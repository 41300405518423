import { paymentDetailsStorage } from '../localPersist'

const state = {
  orderPlaced: false,
  paymentDetails: {
    name: '',
    company: '',
    country: 'Sverige',
    streetAddress: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    emailAddress: ''
  },
  shippingDetails: {
    name: '',
    company: '',
    country: 'Sverige',
    streetAddress: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    sameAsBilling: true
  }
}

const actions = {
  setOrderPlaced ({ commit }, value) {
    commit('setOrderPlaced', value)
  },
  saveShippingDetails ({ commit }, value) {
    commit('saveShippingDetails', value)
  },
  setPaymentDetails ({ commit }, payload) {
    commit('setPaymentDetails', payload)
    paymentDetailsStorage.setItem(payload[0], payload[1])
  },
  setPaymentDetailsLF ({ commit }, payload) {
    commit('setPaymentDetails', payload)
  },
  setShippingDetails ({ commit }, payload) {
    commit('setShippingDetails', payload)
  }
}

const mutations = {
  setOrderPlaced (state, value) {
    state.orderPlaced = value
  },
  saveShippingDetails (state, value) {
    state.shippingDetails = value
  },
  setPaymentDetails (state, payload) {
    state.paymentDetails[payload[0]] = payload[1]
  },
  setShippingDetails (state, payload) {
    state.shippingDetails[payload[0]] = payload[1]
  }
}

const getters = {
  paymentCountry: (state) => {
    return state.paymentDetails.country
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
