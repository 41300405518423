<template>
  <div class="container">
    <div class="columns">
      <div class="column is-one-third">
        <div class="box info-repo">
          <aside class="menu">
            <template v-for="node in info_repository_index">
              <p class="menu-label" :key="node.key">
                {{ node.key }}
              </p>
              <ul class="menu-list" :key="node.entries[0]">
                <li v-for="(subnode, index) in node.entries" :key="index">
                  <a :class="{ 'is-active': activeInfoRepositoryIndex === subnode }" @click="activeInfoRepositoryIndex = subnode" v-if="!Array.isArray(subnode)">{{ subnode }}</a>
                  <ul v-else>
                    <li v-for="subsubnode in subnode" :key="subsubnode">
                    <a :class="{ 'is-active': activeInfoRepositoryIndex === subsubnode }" @click="activeInfoRepositoryIndex = subsubnode">{{ subsubnode }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
          </aside>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <h2 class="title is-2">{{ activeInfoRepositoryIndex }}</h2>
          <div v-bind:is="info_repository[activeInfoRepositoryIndex]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IRWW6TekniskSpec from './tekniskspec.vue'
import IRWW6Utförande from './utforande.vue'
import HeatingCurve from '../HeatingCurve.vue'

export default {
  name: 'IRWW6',
  data: function () {
    return {
      activeInfoRepositoryIndex: 'Teknisk Specifikation',
      info_repository_index: [
        {
          entries: [
            'Teknisk Specifikation',
            'Utförande',
            'Värmekurva'
          ],
          key: 'Information'
        }
      ],
      info_repository: {
        'Teknisk Specifikation': IRWW6TekniskSpec,
        Utförande: IRWW6Utförande,
        Värmekurva: HeatingCurve
      }
    }
  },
  components: {
    IRWW6TekniskSpec,
    IRWW6Utförande,
    HeatingCurve
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>
