<template>
  <div class="navbar-dropdown is-right pb-0">
    <div v-if="!cartEmpty" class="navbar-item">
      <h2 class="title is-2">Kundvagn</h2>
    </div>

    <!-- Shopping Cart empty -->
    <div v-if="cartEmpty" class="mb-2 navbar-item">
      <h4 class="title is-5">Din kundvagn är tom.</h4>
    </div>

    <div v-if="!cartEmpty">
    <product v-for="(sku, index) in productsInCart" :key="index" :sku="sku" class="navbar-item" />
      <hr class="navbar-divider">
      <div class="navbar-item">
        <h3 class="title is-3">
          Delkostnad
        </h3>
      </div>
      <div class="navbar-item">
        <table class="table is-fullwidth">
          <tbody>
            <tr v-for="(segment, index) in cartSegments" :key="index">
              <td>{{ segment.title }}</td>
              <td>{{ segment.value | sek }}</td>
            </tr>
            <tr>
              <td><h4 class="title is-4 has-text-weight-bold">Att betala:</h4></td>
              <td><h4 class="title is-4 has-text-weight-bold">{{ totals['grand_total'].value | sek }}</h4></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <nav v-if="!cartEmpty" class="level">
      <button-full
        class="level-item"
        :link="{ name: 'checkout' }"
      >
        Slutför beställning
      </button-full>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Product from './Product.vue'

import ButtonFull from '../blocks/ButtonFull.vue'

export default {
  name: 'Microcart',
  components: {
    Product,
    ButtonFull
  },
  computed: {
    ...mapGetters({
      totals: 'cart/totals',
      productsInCart: 'cart/productsInCart'
    }),
    cartEmpty () {
      return this.productsInCart.length === 0
    },
    cartSegments () {
      const segments = {}
      for (const segment in this.totals) {
        if (this.totals[segment].code !== 'grand_total') {
          segments[segment] = this.totals[segment]
        }
      }
      return segments
    }
  }
}
</script>
