<template>
  <div id="checkout">
    <section class="section is-main">
      <div class="container is-main checkout">
        <h1 v-show="!orderPlaced" class="title is-1">Slutför Beställning</h1>
        <h1 v-show="orderPlaced" class="title is-1">Tack för din beställning!</h1>
        <cart-summary v-if="!orderPlaced && cartQty > 0" />
        <div class="py-4" v-if="!orderPlaced">
          <shipping />
          <payment />
          <order-review />
        </div>
        <div v-show="orderPlaced">
          <thank-you-page />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Shipping from '../components/checkout/Shipping.vue'
import Payment from '../components/checkout/Payment.vue'
import OrderReview from '../components/checkout/OrderReview.vue'
import CartSummary from '../components/checkout/CartSummary.vue'
import ThankYouPage from '../components/checkout/ThankYouPage.vue'

export default {
  name: 'Checkout',
  computed: {
    ...mapState({ orderPlaced: state => state.checkout.orderPlaced }),
    ...mapGetters({ cartQty: 'cart/totalQuantity' })
  },
  watch: {
    cartQty: function (newQty) {
      if (newQty === 0 && !this.orderPlaced) {
        this.$router.push('/')
      }
    }
  },
  created () {
    document.title = 'Slutför Beställning | Lunna Teknik'
    if (this.cartQty === 0) {
      this.$router.push('/')
    }
  },
  beforeDestroy () {
    this.$store.dispatch('checkout/setOrderPlaced', false)
  },
  components: {
    Shipping,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage
  }
}
</script>
