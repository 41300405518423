<template>
  <div class="pt-5">
    <h3 class="title is-3 has-text-weight-normal">
      {{ shipping.sameAsBilling ? 'Adress' : 'Fakturaaddress' }}
    </h3>
      <personal-details />
        <base-input
          name="name"
          labelText="Namn*"
          :value="payment.name"
          @input.native="setPaymentProp(['name', $event.target.value])"
          @blur="$v.payment.name.$touch()"
          autocomplete="name"
          :validations="[{
            error: $v.payment.name.$error && !$v.payment.name.required,
            text: 'Obligatorisk uppgift'
          }]"
        />

        <base-input
          name="company-name"
          labelText="Företag"
          :value="payment.company"
          @input.native="setPaymentProp(['company', $event.target.value])"
          autocomplete="organization"
        />
        <base-input
          name="street-address"
          labelText="Adress*"
          :value="payment.streetAddress"
          @input.native="setPaymentProp(['streetAddress', $event.target.value])"
          @blur="$v.payment.streetAddress.$touch()"
          autocomplete="address-line1"
          :validations="[{
            error: $v.payment.streetAddress.$error && !$v.payment.streetAddress.required,
            text: 'Obligatorisk uppgift'
          }]"
        />

        <base-input
          name="phone"
          inputmode="numeric"
          labelText="Telefonnummer*"
          :value="payment.phoneNumber"
          @input.native="setPaymentProp(['phoneNumber', $event.target.value])"
          @blur="$v.payment.phoneNumber.$touch()"
          autocomplete="tel"
          :validations="[{
            error: $v.payment.phoneNumber.$error && !$v.payment.phoneNumber.required,
            text: 'Obligatorisk uppgift'
          }]"
        />
        <base-input
          name="zip-code"
          inputmode="numeric"
          labelText="Postnummer*"
          :value="payment.zipCode"
          @input.native="setPaymentProp(['zipCode', $event.target.value])"
          @blur="$v.payment.zipCode.$touch()"
          autocomplete="postal-code"
          :validations="[{
            error: $v.payment.zipCode.$error && !$v.payment.zipCode.required,
            text: 'Obligatorisk uppgift'
          }]"
        />

        <base-input
          name="city"
          labelText="Ort*"
          :value="payment.city"
          @input.native="setPaymentProp(['city', $event.target.value])"
          @blur="$v.payment.city.$touch()"
          autocomplete="address-level2"
          :validations="[{
            error: $v.payment.city.$error && !$v.payment.city.required,
            text: 'Obligatorisk uppgift'
          }]"
        />

    <div class="field">
      <label class="label">Land</label>
      <div class="control">
        <div class="select">
          <select
            name="countries"
            :value="payment.country"
            @change="setPaymentProp(['country', $event.target.value]); $v.payment.country.$touch()"
            autocomplete="country"
          >
            <option value="" disabled selected hidden>Land*</option>
            <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
          </select>
          <span class="validation-error" v-if="$v.payment.country.$error && !$v.payment.country.required">
            {{ 'Obligatorisk uppgift' }}
          </span>
        </div>
      </div>
    </div>

    <base-checkbox
      id="sameAsBillingCheckbox"
      :value="shipping.sameAsBilling"
      @click="setShippingProp(['sameAsBilling', !shipping.sameAsBilling]); resetShipping()"
    >
      Samma leveransadress
    </base-checkbox>
    <template v-if="!shipping.sameAsBilling">
      <h3 class="title is-3 has-text-weight-normal">Leveransadress</h3>
      <base-input
        name="name"
        labelText="Namn*"
        :value="shipping.name"
        @input.native="setShippingProp(['name', $event.target.value])"
        @blur="$v.shipping.name.$touch()"
        autocomplete="name"
        :validations="[{
          error: $v.shipping.name.$error && !$v.shipping.name.required,
          text: 'Obligatorisk uppgift'
        }]"
      />

      <base-input
        name="company-name"
        labelText="Företag"
        :value="shipping.company"
        @input.native="setShippingProp(['company', $event.target.value])"
        autocomplete="company-name"
      />
      <base-input
        name="street-address"
        labelText="Adress*"
        :value="shipping.streetAddress"
        @input.native="setShippingProp(['streetAddress', $event.target.value])"
        @blur="$v.shipping.streetAddress.$touch()"
        autocomplete="address-line1"
        :validations="[{
          error: $v.shipping.streetAddress.$error && !$v.shipping.streetAddress.required,
          text: 'Obligatorisk uppgift'
        }]"
      />

      <base-input
        name="phone"
        inputmode="numeric"
        labelText="Telefonnummer*"
        :value="shipping.phoneNumber"
        @input.native="setShippingProp(['phoneNumber', $event.target.value])"
        @blur="$v.shipping.phoneNumber.$touch()"
        autocomplete="tel"
        :validations="[{
          error: $v.shipping.phoneNumber.$error && !$v.shipping.phoneNumber.required,
          text: 'Obligatorisk uppgift'
        }]"
      />
      <base-input
        name="zip-code"
        inputmode="numeric"
        labelText="Postnummer*"
        :value="shipping.zipCode"
        @input.native="setShippingProp(['zipCode', $event.target.value])"
        @blur="$v.shipping.zipCode.$touch()"
        autocomplete="address-level2"
        :validations="[{
          error: $v.shipping.zipCode.$error && !$v.shipping.zipCode.required,
          text: 'Obligatorisk uppgift'
        }]"
      />

      <base-input
        name="city"
        labelText="Ort*"
        :value="shipping.city"
        @input.native="setShippingProp(['city', $event.target.value])"
        @blur="$v.shipping.city.$touch()"
        autocomplete="city"
        :validations="[{
          error: $v.shipping.city.$error && !$v.shipping.city.required,
          text: 'Obligatorisk uppgift'
        }]"
      />

      <div class="field">
        <label class="label">Land</label>
        <div class="control">
          <div class="select">
            <select
              name="countries"
              :value="shipping.country"
              @change="setShippingProp(['country', $event.target.value]); $v.shipping.country.$touch()"
              autocomplete="country"
            >
              <option value="" disabled selected hidden>Land*</option>
              <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
            </select>
            <span class="validation-error" v-if="$v.shipping.country.$error && !$v.shipping.country.required">
              {{ 'Obligatorisk uppgift' }}
            </span>
          </div>
        </div>
    </div>
    </template>
    <h3 class="title is-3 has-text-weight-normal">
      Frakt
    </h3>
    <div class="tags">
      <a
        v-for="(method, index) in cart.availableCarriers"
        :key="index"
        class="tag is-medium has-no-decoration"
        :class="{ 'is-primary': cart.selectedCarrier.carrier_code === method.carrier_code }"
        @click="setNewCarrier(method)"
      >
        {{ method.carrier_title }} {{ method.price_incl_tax | sek }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Countries from '../../assets/countries.json'

import PersonalDetails from './PersonalDetails.vue'
import BaseCheckbox from '../form/BaseCheckbox.vue'
import BaseInput from '../form/BaseInput.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Shipping',
  data () {
    return {
      countries: Countries
    }
  },
  validations: {
    payment: {
      name: {
        required
      },
      country: {
        required
      },
      streetAddress: {
        required
      },
      zipCode: {
        required
      },
      city: {
        required
      },
      phoneNumber: {
        required
      }
    },
    shipping: {
      name: {
        required
      },
      country: {
        required
      },
      streetAddress: {
        required
      },
      zipCode: {
        required
      },
      city: {
        required
      },
      phoneNumber: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      payment: state => state.checkout.paymentDetails,
      shipping: state => state.checkout.shippingDetails,
      cart: state => state.cart
    })
  },
  methods: {
    setPaymentProp (value) {
      this.$store.dispatch('checkout/setPaymentDetails', value)
      if (value[0] === 'country') {
        this.$store.dispatch('cart/sortPaymentMethods', value)
      }
    },
    setShippingProp (value) {
      this.$store.dispatch('checkout/setShippingDetails', value)
    },
    setNewCarrier (method) {
      this.$store.dispatch('cart/setCarrier', method)
    },
    resetShipping (value) {
      if (!this.shipping.sameAsBilling) {
        for (const i in this.shipping) {
          if (Object.prototype.hasOwnProperty.call(this.payment, i)) {
            this.setShippingProp([i, ''])
          }
        }
        this.setShippingProp(['country', 'Sverige'])
        this.$v.shipping.$reset()
      }
    }
  },
  components: {
    BaseCheckbox,
    BaseInput,
    PersonalDetails
  }
}
</script>
