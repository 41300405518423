import Vue from 'vue'
import { cartStorage } from '../localPersist'

import ShippingMethods from '../../assets/shippingMethods.json'
import PaymentMethods from '../../assets/paymentMethods.json'

const state = {
  cartItems: {},
  lastProduct: '',
  shippingMethods: ShippingMethods,
  paymentMethods: PaymentMethods,
  availablePaymentMethods: {},
  availableCarriers: [],
  selectedCarrier: {},
  selectedPayment: PaymentMethods.invoice
}

const getters = {
  totalQuantity: (state) => {
    const cart = state.cartItems
    return Object.keys(cart).reduce((accumulator, p) => accumulator + parseInt(cart[p]), 0)
  },
  productsInCart: (state) => {
    return Object.keys(state.cartItems)
  },
  totals: (state, _, rootState) => {
    const cart = state.cartItems
    const products = rootState.inventory.products
    const subtotal = Object.keys(cart).reduce((accumulator, p) => accumulator + (parseInt(cart[p]) * products[p].price), 0)
    return {
      subtotalInclTax: {
        code: 'subtotalInclTax',
        title: 'Delsumma inkl. moms',
        value: subtotal
      },
      payment: {
        code: 'payment',
        title: state.selectedPayment.title,
        value: state.selectedPayment.costInclTax
      },
      shipping: {
        code: 'shipping',
        title: state.selectedCarrier.carrier_title,
        value: state.selectedCarrier.price_incl_tax
      },
      grand_total: {
        code: 'grand_total',
        title: 'Totalt',
        value: subtotal + state.selectedCarrier.price_incl_tax + state.selectedPayment.costInclTax
      }
    }
  }
}

const actions = {
  addItem ({ commit, state, dispatch }, { sku, qty }) {
    if (!(sku in state.cartItems)) {
      commit('addItem', { sku, qty })
      dispatch('sortShipping')
      cartStorage.setItem(sku, qty)
    } else {
      const currentQty = state.cartItems[sku]
      const newQty = currentQty + qty
      dispatch('updateQty', { sku, qty: newQty })
      cartStorage.setItem(sku, newQty)
    }
  },
  updateQty ({ commit }, { sku, qty }) {
    commit('updateQty', { sku, qty })
    cartStorage.setItem(sku, qty)
  },
  clearCart ({ commit, dispatch }) {
    commit('clearCart')
    dispatch('sortShipping')
    cartStorage.clear()
  },
  removeItem ({ commit, dispatch }, { sku }) {
    commit('removeItem', sku)
    dispatch('sortShipping')
    cartStorage.removeItem(sku)
  },
  setCarrier ({ commit }, value) {
    commit('setCarrier', value)
  },
  setPaymentMethod ({ commit }, value) {
    commit('setPaymentMethod', value)
  },
  setSelectedPaymentOption ({ commit }, { option, value }) {
    commit('setSelectedPaymentOption', { option, value })
  },
  sortPaymentMethods ({ state, rootGetters, getters, commit }) {
    const paymentCountry = rootGetters['checkout/paymentCountry']
    const availPayments = {}
    for (const key in state.paymentMethods) {
      const countryCheck = state.paymentMethods[key].availableCountries.includes(paymentCountry)
      const sumCheck = state.paymentMethods[key].minTotalSum < getters.totals.grand_total.value
      if (countryCheck && sumCheck) {
        availPayments[key] = state.paymentMethods[key]
      }
    }
    if (!(state.selectedPayment.code in availPayments)) {
      commit('setPaymentMethod', 'invoice')
    }
    commit('sortPaymentMethods', availPayments)
  },
  sortShipping ({ commit, state, rootState }) {
    const cart = state.cartItems
    const products = rootState.inventory.products
    const methodsInCart = Object.keys(cart)
      .map(x => products[x].shipping_methods)
      .flat()
      .map(y => state.shippingMethods[y])
      .filter((item, idx, arr) => arr.indexOf(item) === idx)

    const priorityLevel = methodsInCart
      .reduce((max, element) => element.priority > max ? element.priority : max, 0)

    const availableMethods = methodsInCart
      .filter(method => method.priority === priorityLevel)

    // if (priorityLevel > 1) {
    //   availableMethods.push(state.shippingMethods.lt_warehouse)
    // }

    const selectedMethod = availableMethods
      .reduce((max, element) => element.price_incl_tax > max ? element.price_incl_tax : max, availableMethods[0])

    commit('setShipping', { sShipping: selectedMethod, aShipping: availableMethods })
  },
  setLastProduct ({ commit, rootState }, { sku }) {
    const product = rootState.inventory.products[sku]
    commit('setLastProduct', product.name)
  }
}

const mutations = {
  addItem (state, { sku, qty }) {
    Vue.set(state.cartItems, sku, qty)
  },
  updateQty (state, { sku, qty }) {
    state.cartItems[sku] = qty
  },
  clearCart (state) {
    state.cartItems = {}
  },
  removeItem (state, sku) {
    Vue.delete(state.cartItems, sku)
  },
  setCarrier (state, value) {
    state.selectedCarrier = value
  },
  setPaymentMethod (state, value) {
    state.selectedPayment = state.paymentMethods[value]
  },
  sortPaymentMethods (state, availPayments) {
    state.availablePaymentMethods = availPayments
  },
  setSelectedPaymentOption (state, { option, value }) {
    state.selectedPayment[option] = value
  },
  setShipping (state, { sShipping, aShipping }) {
    state.availableCarriers = aShipping
    state.selectedCarrier = sShipping
  },
  setLastProduct (state, productName) {
    state.lastProduct = productName
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
