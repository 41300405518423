<template>
  <div>
    <h3 class="title is-3">Din kundvagn</h3>
    <table class="table is-fullwidth is-hidden-mobile table-align-middle">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Produkt</th>
          <th>Antal</th>
          <th>Pris</th>
          <th>Total</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <product v-for="(sku, index) in productsInCart" :key="index" :sku="sku"/>
      </tbody>
    </table>
    <mobile-cart-table v-for="(sku, index) in productsInCart" :key="index" :sku="sku"/>
    <hr>
    <table class="table is-fullwidth is-striped">
      <tbody>
        <tr v-for="(segment, index) in cartSegments" :key="index">
          <td>{{ segment.title }}</td>
          <td class="has-text-right">{{ segment.value | sek }}</td>
        </tr>
        <tr>
          <td>{{ totals['grand_total'].title }} inkl. moms</td>
          <td class="has-text-right">{{ totals['grand_total'].value | sek }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Product from './Product.vue'
import MobileCartTable from './MobileCartTable.vue'

export default {
  name: 'CartSummary',
  components: {
    Product,
    MobileCartTable
  },
  computed: {
    ...mapGetters({
      totals: 'cart/totals',
      productsInCart: 'cart/productsInCart'
    }),
    cartSegments () {
      const segments = {}
      for (const segment in this.totals) {
        if (this.totals[segment].code !== 'grand_total') {
          segments[segment] = this.totals[segment]
        }
      }
      return segments
    }
  }
}
</script>
