import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuelidate from 'vuelidate'
import VueHighcharts from 'vue-highcharts'
import { cartStorage, paymentDetailsStorage } from './store/localPersist'

if (process.env.NODE_ENV === 'development') {
  Vue.prototype.$apiURL = 'http://192.168.1.136:3050/api'
}
if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$apiURL = 'https://lunnateknik.se/api'
}

Vue.use(Vuelidate)
Vue.use(VueHighcharts)

Vue.filter('sek', (value) => value.toLocaleString('sv-SE', { style: 'currency', currency: 'SEK', maximumFractionDigits: 0, minimumFractionDigits: 0 }))
cartStorage.iterate(function (value, key) {
  store.dispatch('cart/addItem', { sku: key, qty: value })
})
paymentDetailsStorage.iterate(function (value, key) {
  store.dispatch('checkout/setPaymentDetailsLF', [key, value])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
